@font-face {
    font-family: 'National-Narrow';
    src: url('National-Narrow-Bold.otf') format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: 'National-Narrow';
    src: url('National-Narrow-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'National-Narrow';
    src: url('National-Narrow-Regular.otf') format('opentype');
    font-weight: normal;
}