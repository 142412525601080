@font-face {
    font-family: 'National';
    src: url('National-Bold.otf') format('opentype');
    font-weight: bold;
}

@font-face {
    font-family: 'National';
    src: url('National-Semibold.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'National';
    src: url('National-Regular.otf') format('opentype');
    font-weight: normal;
}