/* autoprefixer grid: autoplace */
@import "assets/css/fonts.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--fontFamilyBase: "National", sans-serif;
	--fontFamilySecond: "National-Narrow", sans-serif;

	--primary-red-default: #DA1A31;
	--primary-red-900: #BF001C;
	--primary-red-800: #CD0E29;
	--primary-red-700: #DA1A31;
	--primary-red-600: #EC2737;
	--primary-red-500: #FB3237;
	--primary-red-400: #F54952;
	--primary-red-300: #EA6E74;
	--primary-red-200: #F3979B;
	--primary-red-100: #FFCCD3;
	--primary-red-50: #FFEBEF;

	--primary-darken: #00213D;
	--primary-default: #00365F;
	--primary-900: #00365F;
	--primary-800: #0E517E;
	--primary-700: #18608F;
	--primary-600: #24709F;
	--primary-500: #2F7CAA;
	--primary-400: #498DB5;
	--primary-300: #649FBF;
	--primary-200: #8BBAD2;
	--primary-100: #B6D4E3;
	--primary-50: #E1EEF3;

	--neutral-1000: #000000;
	--neutral-900: #1C1C1E;
	--neutral-800: #2C2C2E;
	--neutral-700: #3A3A3C;
	--neutral-600: #48484A;
	--neutral-500: #636366;
	--neutral-400: #8E8E93;
	--neutral-300: #AEAEB2;
	--neutral-200: #C7C7CC;
	--neutral-100: #E5E5EA;
	--neutral-50: #F2F2F5;
	--neutral-25: #FAFAFB;
	--neutral-0: #FFFFFF;

	--secondary-blue: #0088CE;
	--secondary-green: #405E18;
	--secondary-red: #6E0121;

	--text-light: #FFFFFF;
	--text-dark: #23262B;
	--text-inactive: #636366;
	--text-active: #00365F;

	--input-text-default: #636366;
	--input-text-active: #00365F;
	--input-field-background: #F2F2F7;
	--input-border-default: #C7C7CC;
	--input-border-active: #00365F;

	--invalid-color: #F44336;
	--warning-color: #ED6C02;
	--success-color: #4CAF50;
	--success-color-20: #DBEFDC;

}

html, body {
	font-family: var(--fontFamilyBase);
	min-height: 100%;
	height: 100%;
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
	overflow: auto;
}

body {
	background: #FFFFFF;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

p {
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.2px;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--fontFamilyBase);
	font-weight: 900;
}

h1 {
	font-size: 40px;
	line-height: 48px;
}

h2 {
    font-size: 26px;
	line-height: 36px;
}

h3 {
    font-size: 20px;
	line-height: 28px;
}

h4 {
    font-size: 18px;
    line-height: 25px;
}

h5 {
	font-family: var(--fontFamilyBase);
	font-size: 18px;
	line-height: 20px;
}

h6 {
	font-family: var(--fontFamilyBase);
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
}

input,
button,
select {
	outline: none;
	font-family: var(--fontFamilyBase);
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}

.overflow-popover .MuiBackdrop-root {
	opacity: 0 !important;
}

/*Fix for popover arrow if needed*/
.overflow-popover > .MuiPaper-root {
	overflow: visible;
}